<template>
  <div>
    <a-card :tabList="tabList" :activeTabKey="currentTab" @tabChange="tabChange">
      <template slot="accountPanelTitle"> 账号管理 </template>
      <template slot="reviewPanelTitle">
        <span> 待审核账号 </span>
        <a-badge :count="reviewCount"><span style="color: #ffff">_</span></a-badge>
      </template>
      <div v-if="currentTab == 'accountPanel'">
        <AccountPanel />
      </div>
      <div v-else-if="currentTab == 'reviewPanel'">
        <ReviewPanel />
      </div>
    </a-card>
  </div>
</template>

<script>
import { reviewList } from "@/apis/data";

export default {
  components: {
    ReviewPanel: () => import("./ReviewPanel"),
    AccountPanel: () => import("./AccountPanel"),
  },
  data() {
    return {
      tabList: [
        {
          key: "accountPanel",
          scopedSlots: { tab: "accountPanelTitle" },
        },
        {
          key: "reviewPanel",
          scopedSlots: { tab: "reviewPanelTitle" },
        },
      ],
      currentTab: undefined,
      reviewCount: 0,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }

      reviewList({ status: "pending" }).then((data) => {
        this.reviewCount = data.count;
      });
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
